<div class="header-container" *ngIf="showHeader$ | async">
  <div
    class="refresh-enterprise-container"
    *ngIf="isLocalEnvironment && (systemSegment$ | async) == null"
  >
    <mat-icon class="refresh-icon" (click)="refreshEnterprise()">
      refresh
    </mat-icon>
  </div>

  <div class="logo-container">
    <img
      (click)="confirmGoBack()"
      class="img-fluid c-pointer"
      [src]="(enterprise$ | async)?.transparentLogoUrl"
      *ngIf="shouldShowMenuList$ | async"
    />
    <img
      class="img-fluid"
      [src]="(enterprise$ | async)?.transparentLogoUrl"
      *ngIf="!(shouldShowMenuList$ | async)"
    />
  </div>

  <div class="d-flex justify-content-end align-items-center">
    <div class="menu-container ms-3" *ngIf="shouldShowMenuList$ | async">
      <div
        routerLink="autenticacao/entrar"
        *ngIf="(isLoggedIn$ | async) === false"
      >
        <div class="login-button d-flex">
          Entrar
          <fa-icon class="ms-1" [icon]="faSignIn"></fa-icon>
        </div>
      </div>

      <div class="logged-in-container" *ngIf="isLoggedIn$ | async">
        <div
          class="user-info pe-3 ps-1 me-3"
          *ngIf="!(isMobileScreen$ | async)"
        >
          <p>
            <span>Tipo de acesso:</span>
            {{
              (decodedToken$ | async)?.accessType === 1 ? "Corretor" : "Cliente"
            }}
          </p>
          <p><span>Nome:</span> {{ (decodedToken$ | async)?.name }}</p>
          <p><span>E-mail:</span> {{ (decodedToken$ | async)?.email }}</p>
        </div>

        <div
          (click)="showCartPopup()"
          *ngIf="shouldShowShoppingCartButton$ | async"
        >
          <div class="cart-amount-container">
            <span class="cart-amount-content">{{
              shoppingCartPlansAmount$ | async
            }}</span>
          </div>
          <mat-icon class="shopping-cart-icon mt-1"> shopping_cart </mat-icon>
        </div>

        <div>
          <button class="menu-button">
            <mat-icon
              class="menu-icon"
              mat-icon-button
              [matMenuTriggerFor]="menu"
            >
              menu
            </mat-icon>
          </button>
          <mat-menu #menu="matMenu" xPosition="before">
            <button
              *ngIf="(isLoggedIn$ | async) && (accessType$ | async) !== 2"
              mat-menu-item
              routerLink="/portal-do-corretor"
            >
              <mat-icon> home </mat-icon>
              Área do Corretor
            </button>

            <button
              *ngIf="isLoggedIn$ | async"
              mat-menu-item
              routerLink="simulacao/lista"
              [disabled]="isMySimulationsPage()"
              (click)="clearSimulationStep()"
            >
              <mat-icon> view_list </mat-icon>
              Simulações
            </button>

            <button
              mat-menu-item
              routerLink="venda/lista"
              *ngIf="isLoggedIn$ | async"
            >
              <mat-icon> folder_open </mat-icon>
              Propostas
            </button>
            <button mat-menu-item (click)="onLogoutClick()">
              <mat-icon *ngIf="isLoggedIn$ | async">logout</mat-icon>Sair
            </button>
          </mat-menu>
        </div>
      </div>
    </div>
  </div>
</div>

<app-cart-popup></app-cart-popup>
