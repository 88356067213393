import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { LoaderService } from 'src/app/core/services/loader';
import { SignInResponse } from 'src/app/features/auth/interfaces/SignInResponse';
import { CepService } from 'src/app/features/sale/services/cep/cep.service';

import { AccessType } from '../../enums/AccessType';
import { DecodedToken } from '../../models/DecodedToken';
import { AuthService } from '../../services/auth';
import { jwtDecoder } from '../../utils/jwt-decoder';
import { goToSimulationPage } from '../core/core.actions';
import {
  showNotificationInfo,
  showNotificationSuccess,
} from '../notification/notification.actions';
import {
  createBrokerError,
  createBrokerRequest,
  createBrokerSuccess,
  loadBrokerByCpfError,
  loadBrokerByCpfRequest,
  loadBrokerByCpfSuccess,
  loadCepCreateBrokerError,
  loadCepCreateBrokerRequest,
  loadCepCreateBrokerSuccess,
  logout,
  requestBrokerCredentialsError,
  requestBrokerCredentialsRequest,
  requestBrokerCredentialsSuccess,
  requestClientPasswordError,
  requestClientPasswordRequest,
  requestClientPasswordSuccess,
  resetClientPasswordError,
  resetClientPasswordRequest,
  resetClientPasswordSuccess,
  signInBrokerRequest,
  signInClientRequest,
  signInError,
  signInSuccess,
  signOnError,
  signOnRequest,
  signOnSuccess,
} from './auth.actions';

@Injectable()
export class AuthEffects {
  signOn$ = createEffect(() =>
    this.actions$.pipe(
      ofType(signOnRequest),
      tap(() => this.loader.show('Cadastrando...')),
      switchMap((action) =>
        this.authService.clientSignOn(action.payload).pipe(
          map(() => signOnSuccess()),
          catchError((e) => of(signOnError({ payload: e.error })))
        )
      ),
      tap(() => this.loader.hide())
    )
  );

  signOnSuccessNotification$ = createEffect(() =>
    this.actions$.pipe(
      ofType(signOnSuccess),
      map(() =>
        showNotificationSuccess({ payload: 'Cadastro efetuado com sucesso.' })
      )
    )
  );

  signOnSuccessRedirect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(signOnSuccess),
        tap(() => this.router.navigate(['autenticacao', 'entrar']))
      ),
    { dispatch: false }
  );

  createBroker$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createBrokerRequest),
      tap(() => this.loader.show('Salvando corretor(a)...')),
      switchMap(({ payload }) =>
        this.authService.brokerSignOn(payload).pipe(
          map(() => createBrokerSuccess()),
          catchError(() => of(createBrokerError()))
        )
      ),
      tap(() => this.loader.hide())
    )
  );

  createBrokerSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createBrokerSuccess),
      map(() =>
        showNotificationSuccess({
          payload: 'Cadastro efetuado com sucesso!',
        })
      ),
      tap(() => this.router.navigate(['autenticacao', 'entrar']))
    )
  );

  loadBrokerByCpfRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadBrokerByCpfRequest),
      tap(() => this.loader.show('Buscando corretor(a)...', true)),
      switchMap(({ payload: cpf }) =>
        this.authService.getBrokerByCpf(cpf).pipe(
          map((broker) => loadBrokerByCpfSuccess({ payload: broker })),
          catchError(() => of(loadBrokerByCpfError()))
        )
      ),
      tap(() => this.loader.hide())
    )
  );

  signInBroker$ = createEffect(() =>
    this.actions$.pipe(
      ofType(signInBrokerRequest),
      tap(() => this.loader.show('Entrando...', true)),
      switchMap(({ payload }) =>
        this.authService.brokerSignIn(payload).pipe(
          map((response) =>
            signInSuccess({
              payload: {
                signInResponse: response,
                decodedToken: this.getDecodedTokenByToken(response),
              },
            })
          ),
          catchError(() => of(signInError()))
        )
      ),
      tap(() => this.loader.hide())
    )
  );

  signInClient$ = createEffect(() =>
    this.actions$.pipe(
      ofType(signInClientRequest),
      tap(() => this.loader.show('Entrando...', true)),
      switchMap((action) =>
        this.authService.clientSignIn(action.payload).pipe(
          map((response) =>
            signInSuccess({
              payload: {
                signInResponse: response,
                decodedToken: this.getDecodedTokenByToken(response),
              },
            })
          ),
          catchError(() => of(signInError()))
        )
      ),
      tap(() => this.loader.hide())
    )
  );

  signInSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(signInSuccess),
      map(() =>
        showNotificationSuccess({
          payload: 'Bem vindo(a) ao Simulador de planos!',
        })
      )
    )
  );

  signInSuccessRedirect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(signInSuccess),
      map(() => goToSimulationPage())
    )
  );

  logout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(logout),
        tap(() => {
          this.router.navigate(['autenticacao', 'entrar']);
        })
      ),
    { dispatch: false }
  );

  requestBrokerCredentials$ = createEffect(() =>
    this.actions$.pipe(
      ofType(requestBrokerCredentialsRequest),
      tap(() =>
        this.loader.show(
          'Verificando CPF e enviando e-mail com seu login e senha...',
          true
        )
      ),
      switchMap(({ payload: cpf }) =>
        this.authService.requestBrokerCredentials(cpf).pipe(
          map((response) =>
            requestBrokerCredentialsSuccess({ payload: response })
          ),
          catchError(() => of(requestBrokerCredentialsError()))
        )
      ),
      tap(() => this.loader.hide())
    )
  );

  requestBrokerCredentialsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(requestBrokerCredentialsSuccess),
      tap(() => this.router.navigate(['autenticacao', 'entrar'])),
      map(({ payload: response }) =>
        showNotificationSuccess({ payload: response })
      )
    )
  );

  requestClientPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(requestClientPasswordRequest),
      tap(() => this.loader.show('Enviando link de recuperação...', true)),
      switchMap(({ payload: email }) =>
        this.authService.requestClientPassword(email).pipe(
          map(() => requestClientPasswordSuccess()),
          catchError((e) =>
            of(requestClientPasswordError({ payload: e.error }))
          )
        )
      ),
      tap(() => this.loader.hide())
    )
  );

  requestClientPasswordSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(requestClientPasswordSuccess),
      tap(() => this.router.navigate(['autenticacao', 'entrar'])),
      map(() =>
        showNotificationSuccess({ payload: 'E-mail enviado com sucesso!' })
      )
    )
  );

  requestClientPasswordError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(requestClientPasswordError),
      map((response) =>
        showNotificationInfo({
          payload: response.payload[0],
        })
      )
    )
  );

  resetClientPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(resetClientPasswordRequest),
      tap(() => this.loader.show('Alterando senha...')),
      switchMap((action) =>
        this.authService.resetClientPassword(action.payload).pipe(
          map(() => resetClientPasswordSuccess()),
          catchError(() => of(resetClientPasswordError()))
        )
      ),
      tap(() => this.loader.hide())
    )
  );

  resetClientPasswordSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(resetClientPasswordSuccess),
      tap(() => this.router.navigate(['autenticacao', 'entrar'])),
      map(() =>
        showNotificationSuccess({ payload: 'Senha alterada com sucesso!' })
      )
    )
  );

  resetClientPasswordError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(resetClientPasswordError),
      map(() => showNotificationInfo({ payload: 'Erro ao alterar a senha' }))
    )
  );

  loadCepCreateBroker$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCepCreateBrokerRequest),
      tap(() => this.loader.show('Carregando dados do CEP...')),
      switchMap(({ payload: cep }) =>
        this.cepService.getCepData(cep).pipe(
          map((response) => loadCepCreateBrokerSuccess({ payload: response })),
          catchError(() => of(loadCepCreateBrokerError()))
        )
      ),
      tap(() => this.loader.hide())
    )
  );

  loadCepCreateBrokerError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCepCreateBrokerError),
      map(() =>
        showNotificationInfo({
          payload: 'Nenhum dado encontrado para o CEP informado',
        })
      )
    )
  );

  constructor(
    private router: Router,
    private actions$: Actions,
    private loader: LoaderService,
    private cepService: CepService,
    private authService: AuthService
  ) {}

  private getDecodedTokenByToken(response: SignInResponse): DecodedToken {
    const decoded = jwtDecoder(response.accessToken);

    const accessType =
      decoded?.AccessType === 'Client' ? AccessType.Client : AccessType.Broker;

    return {
      accessType,
      email: decoded.email,
      id: decoded.Id,
      name: decoded.name,
      phoneNumber: response.phoneNumber,
    };
  }
}
