import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SHOULD_ADD_TENANT_WITHOUT_BRANCH } from 'src/app/core/interceptors/auth';
import { DefaultResponse } from 'src/app/core/models/DefaultResponse';
import endpoints from 'src/environments/endpoints';
import { BeneficiaryType } from '../../types/BeneficiaryType';

// eslint-disable-next-line max-len
import { BrokerRegister } from 'src/app/features/broker-area/interfaces/BrokerRegister';
import { GetAwardExtractPayload } from 'src/app/features/broker-area/interfaces/GetAwardExtractPayload';
import { GetExtractSalesPayload } from 'src/app/features/broker-area/interfaces/GetExtractSalesPayload';
import { SaveBrokerRegisterPayload } from 'src/app/features/broker-area/interfaces/SaveBrokerRegisterPayload';
import { convertAutomaticDebitBankProtheusToAutomaticDebitBank } from '../../converters/ConvertAutomaticDebitBankProtheusToAutomaticDebitBank';
import { convertGracePeriodProtheusToGracePeriod } from '../../converters/ConvertGracePeriodProtheusToGracePeriod';
import { convertProponentDPSProtheusToProponentDPS } from '../../converters/ConvertProponentDPSProtheusToProponentDPS';
import { ProposalProtheusDocument } from '../../interfaces/proposal-protheus/ProposalProtheusDocument';
import { AutomaticDebitBank } from '../../interfaces/protheus/AutomaticDebitBank';
import { GracePeriod } from '../../interfaces/protheus/GracePeriod';
import { PaymentMethods } from '../../interfaces/protheus/PaymentMethods';
import { ProponentDPSQuestion } from '../../interfaces/protheus/ProponentDPSQuestion';
import { ProposalEffectiveDate } from '../../interfaces/protheus/ProposalEffectiveDate';
import { TaxaDeAngariacao } from '../../interfaces/taxa-angariacao/TaxaDeAngariacao';

@Injectable({
  providedIn: 'root',
})
export class ProtheusService {
  private baseURL = endpoints.protheus;

  private context = new HttpContext().set(
    SHOULD_ADD_TENANT_WITHOUT_BRANCH,
    true
  );

  constructor(private http: HttpClient) {}

  getAutomaticDebitBanks(
    guidProposal: string
  ): Observable<AutomaticDebitBank[]> {
    const url = `${this.baseURL}BancosDebitoAutomatico/${guidProposal}`;

    return this.http
      .get<DefaultResponse<AutomaticDebitBank[]>>(url, {
        context: this.context,
      })
      .pipe(
        map((res) =>
          res?.data.map((automaticDebitBank) =>
            convertAutomaticDebitBankProtheusToAutomaticDebitBank(
              automaticDebitBank
            )
          )
        )
      );
  }

  getKinship(
    guidProposal: string,
    beneficiaryType: BeneficiaryType,
    birthDate: string
  ): Observable<any> {
    const url = `${this.baseURL}GrauParentesco/${guidProposal}/${beneficiaryType}/${birthDate}`;

    return this.http
      .get<DefaultResponse<any>>(url, {
        context: this.context,
      })
      .pipe(map((res) => res?.data));
  }

  getProposalEffectiveDates(
    guidProposal: string
  ): Observable<ProposalEffectiveDate[]> {
    const url = `${this.baseURL}StartDate/${guidProposal}`;

    // return of([
    //   {
    //     diaVencimento: '01',
    //     vigencia: '15/02/2023',
    //   },
    // ]);

    return this.http
      .get<DefaultResponse<ProposalEffectiveDate[]>>(url, {
        context: this.context,
      })
      .pipe(map((res) => res?.data));
  }

  getGracePeriod(guidProposal: string): Observable<GracePeriod[]> {
    const url = `${this.baseURL}Carencia/${guidProposal}`;

    return this.http
      .get<DefaultResponse<GracePeriod[]>>(url, {
        context: this.context,
      })
      .pipe(
        map((res) =>
          res?.data.map((gracePeriod) =>
            convertGracePeriodProtheusToGracePeriod(gracePeriod)
          )
        )
      );
  }

  getProtheusDocuments(
    guidProposal: string
  ): Observable<ProposalProtheusDocument[]> {
    const url = `${this.baseURL}Documents/${guidProposal}`;

    return this.http
      .get<DefaultResponse<ProposalProtheusDocument[]>>(url, {
        context: this.context,
      })
      .pipe(map((res) => res?.data));
  }

  getProtheusProponentDocuments(
    guidProposal: string,
    beneficiaryType: string
  ): Observable<ProposalProtheusDocument[]> {
    const url = `${this.baseURL}ProponentDocuments/${guidProposal}/${beneficiaryType}`;

    return this.http
      .get<DefaultResponse<ProposalProtheusDocument[]>>(url, {
        context: this.context,
      })
      .pipe(map((res) => res?.data));
  }

  getProponentDPSQuestions(
    guidProposal: string
  ): Observable<ProponentDPSQuestion[]> {
    const url = `${this.baseURL}ProponentDPS/${guidProposal}`;

    return this.http
      .get<DefaultResponse<ProponentDPSQuestion[]>>(url, {
        context: this.context,
      })
      .pipe(
        map((res) =>
          res?.data.map((proponentDPS) =>
            convertProponentDPSProtheusToProponentDPS(proponentDPS)
          )
        )
      );
  }

  sendProposal(guidProposal: string): Observable<any> {
    const url = `${this.baseURL}SendProposal/${guidProposal}`;

    return this.http.post(
      url,
      {},
      {
        context: this.context,
      }
    );
  }

  getProtheusDependentDistinctPlan(guidProposal: string): Observable<boolean> {
    const url = `${this.baseURL}DependentePlanoDistinto/${guidProposal}`;

    return this.http
      .get<DefaultResponse<boolean>>(url, { context: this.context })
      .pipe(map((res) => res?.data));
  }

  getProtheusFundraising(guidProposal: string): Observable<TaxaDeAngariacao> {
    const url = `${this.baseURL}TaxaDeAngariacao/${guidProposal}`;

    // return of({
    //   fundraisingType: '3',
    //   value: '30',
    // });

    return this.http
      .get<DefaultResponse<TaxaDeAngariacao>>(url, { context: this.context })
      .pipe(map((res) => res?.data));
  }

  getProtheusPaymentMethods(guidProposal: string): Observable<PaymentMethods> {
    const url = `${this.baseURL}FormOfPayment/${guidProposal}`;

    return this.http
      .get<DefaultResponse<PaymentMethods>>(url, { context: this.context })
      .pipe(map((res) => res?.data));
  }

  getProtheusAwardsExtract({
    searchType,
    initialDate,
    finalDate,
  }: GetAwardExtractPayload): Observable<any[]> {
    const url = `${this.baseURL}GetExtractAwards/${searchType}/${initialDate}/${finalDate}`;

    return this.http
      .get<DefaultResponse<any>>(url, { context: this.context })
      .pipe(map((res) => res.data));
  }

  getProtheusBrokerRegister(): Observable<BrokerRegister> {
    const url = `${this.baseURL}BrokerRegister`;

    return this.http
      .get<DefaultResponse<BrokerRegister>>(url, { context: this.context })
      .pipe(map((res) => res.data));
  }

  saveProtheusBrokerRegister(
    payload: SaveBrokerRegisterPayload
  ): Observable<void> {
    const url = `${this.baseURL}BrokerRegister`;

    return this.http.post<void>(url, payload, { context: this.context });
  }

  getProtheusExtractSales(
    searchType: string,
    payload: GetExtractSalesPayload
  ): Observable<any> {
    const url = `${this.baseURL}GetExtractSales/${searchType}`;
    let params: Partial<GetExtractSalesPayload>;

    if (searchType === 'proposta') {
      params = {
        proposal: payload.proposal,
      };
    } else {
      params = {
        initialDate: payload.initialDate,
        finalDate: payload.finalDate,
      };
    }

    return this.http
      .get<DefaultResponse<any>>(url, {
        context: this.context,
        params,
      })
      .pipe(map((res) => res.data));
  }

  getProtheusCardNumber(cpf: string): Observable<any> {
    const url = `${this.baseURL}GetCardPlan/${cpf}`;

    return this.http
      .get<DefaultResponse<any>>(url, { context: this.context })
      .pipe(map((res) => res.data));
  }

  getProtheusHealthBrokers(segment: string): Observable<any> {
    const url = `${this.baseURL}GetBrokers/${segment}`;

    return this.http
      .get<DefaultResponse<any>>(url, { context: this.context })
      .pipe(map((res) => res.data));
  }

  getProtheusDefaulters(): Observable<any> {
    const url = `${this.baseURL}GetDefaulters`;

    return this.http
      .get<DefaultResponse<any>>(url, { context: this.context })
      .pipe(map((res) => res.data));
  }
}
