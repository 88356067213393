import { createAction, props } from '@ngrx/store';
import { BrokerSignInPayload } from 'src/app/features/auth/interfaces/BrokerSignInPayload';
import { SignInResponse } from 'src/app/features/auth/interfaces/SignInResponse';
import { SignOnPayload } from 'src/app/features/auth/interfaces/SignOnPayload';
import { CepIBGEResponse } from 'src/app/features/sale/interfaces/CepIBGEResponse';
import { DecodedToken } from '../../models/DecodedToken';
import { Prop } from '../../models/Prop';
import { AccessType } from '../../enums/AccessType';
import { ClientSignInPayload } from 'src/app/features/auth/interfaces/ClientSignInPayload';
import { CreateBrokerPayload } from 'src/app/features/auth/interfaces/CreateBrokerPayload';
import { ResetClientPasswordPayload } from 'src/app/features/auth/interfaces/ResetClientPasswordPayload';
import { BrokerProtheus } from 'src/app/features/auth/interfaces/BokerProtheus';

enum AuthActions {
  SetAccessType = '[Auth] Set Access Type',

  SignInClientRequest = '[Auth] Sign In Client Request',

  SignInSuccess = '[Auth] Sign In Success',
  SignInError = '[Auth] Sign In Error',

  SignInBrokerRequest = '[Auth] Sign In Broker Request',

  SignOnRequest = '[Auth] Sign On Request',
  SignOnSuccess = '[Auth] Sign On Success',
  SignOnError = '[Auth] Sign On Error',

  CreateBrokerRequest = '[Auth] Create Broker Request',
  CreateBrokerSuccess = '[Auth] Create Broker Success',
  CreateBrokerError = '[Auth] Create Broker Error',

  LoadBrokerByCpfRequest = '[Auth] Load Broker By CPF Request',
  LoadBrokerByCpfSuccess = '[Auth] Load Broker By CPF Success',
  LoadBrokerByCpfError = '[Auth] Load Broker By CPF Error',

  ClearBrokerProtheus = '[Auth] Clear Broker Protheus',

  Logout = '[Auth] Logout',

  RequestBrokerCredentialsRequest = '[Auth] Request Broker Credentials Request',
  RequestBrokerCredentialsSuccess = '[Auth] Request Broker Credentials Success',
  RequestBrokerCredentialsError = '[Auth] Request Broker Credentials Error',

  RequestClientPasswordRequest = '[Auth] Request Client Password Request',
  RequestClientPasswordSuccess = '[Auth] Request Client Password Success',
  RequestClientPasswordError = '[Auth] Request Client Password Error',

  ResetClientPasswordRequest = '[Auth] Reset Client Password Request',
  ResetClientPasswordSuccess = '[Auth] Reset Client Password Success',
  ResetClientPasswordError = '[Auth] Reset Client Password Error',

  LoadCepCreateBrokerRequest = '[Core] Load Cep Create Broker Request',
  LoadCepCreateBrokerSuccess = '[Core] Load Cep Create Broker Success',
  LoadCepCreateBrokerError = '[Core] Load Cep Create Broker Error',

  ClearAuthState = '[Auth] Clear Auth State',
}

export const setAccessType = createAction(
  AuthActions.SetAccessType,
  props<Prop<AccessType>>()
);

export const signInClientRequest = createAction(
  AuthActions.SignInClientRequest,
  props<Prop<ClientSignInPayload>>()
);

export const signInSuccess = createAction(
  AuthActions.SignInSuccess,
  props<Prop<{ signInResponse: SignInResponse; decodedToken: DecodedToken }>>()
);

export const signInError = createAction(AuthActions.SignInError);

export const signInBrokerRequest = createAction(
  AuthActions.SignInBrokerRequest,
  props<Prop<BrokerSignInPayload>>()
);

export const signOnRequest = createAction(
  AuthActions.SignOnRequest,
  props<Prop<SignOnPayload>>()
);

export const signOnSuccess = createAction(AuthActions.SignOnSuccess);

export const signOnError = createAction(
  AuthActions.SignOnError,
  props<Prop<string>>()
);

export const createBrokerRequest = createAction(
  AuthActions.CreateBrokerRequest,
  props<Prop<CreateBrokerPayload>>()
);

export const createBrokerSuccess = createAction(
  AuthActions.CreateBrokerSuccess
);

export const createBrokerError = createAction(AuthActions.CreateBrokerError);

export const loadBrokerByCpfRequest = createAction(
  AuthActions.LoadBrokerByCpfRequest,
  props<Prop<string>>()
);

export const loadBrokerByCpfSuccess = createAction(
  AuthActions.LoadBrokerByCpfSuccess,
  props<Prop<BrokerProtheus>>()
);

export const loadBrokerByCpfError = createAction(
  AuthActions.LoadBrokerByCpfError
);

export const clearBrokerProtheus = createAction(
  AuthActions.ClearBrokerProtheus
);

export const logout = createAction(AuthActions.Logout);

export const requestBrokerCredentialsRequest = createAction(
  AuthActions.RequestBrokerCredentialsRequest,
  props<Prop<string>>()
);

export const requestBrokerCredentialsSuccess = createAction(
  AuthActions.RequestBrokerCredentialsSuccess,
  props<Prop<string>>()
);

export const requestBrokerCredentialsError = createAction(
  AuthActions.RequestBrokerCredentialsError
);

export const requestClientPasswordRequest = createAction(
  AuthActions.RequestClientPasswordRequest,
  props<Prop<string>>()
);

export const requestClientPasswordSuccess = createAction(
  AuthActions.RequestClientPasswordSuccess
);

export const requestClientPasswordError = createAction(
  AuthActions.RequestClientPasswordError,
  props<Prop<string[]>>()
);

export const resetClientPasswordRequest = createAction(
  AuthActions.ResetClientPasswordRequest,
  props<Prop<ResetClientPasswordPayload>>()
);

export const resetClientPasswordSuccess = createAction(
  AuthActions.ResetClientPasswordSuccess
);

export const resetClientPasswordError = createAction(
  AuthActions.ResetClientPasswordError
);

export const clearAuthState = createAction(AuthActions.ClearAuthState);

export const loadCepCreateBrokerRequest = createAction(
  AuthActions.LoadCepCreateBrokerRequest,
  props<Prop<string>>()
);

export const loadCepCreateBrokerSuccess = createAction(
  AuthActions.LoadCepCreateBrokerSuccess,
  props<Prop<CepIBGEResponse>>()
);

export const loadCepCreateBrokerError = createAction(
  AuthActions.LoadCepCreateBrokerError
);
