import { environment } from './environment';

const baseURL = environment.baseURL;

export default {
  clients: `${baseURL}/clients`,
  filters: `${baseURL}/plans/filters`,
  simulations: `${baseURL}/simulations`,
  simulationsPlans: `${baseURL}/simulationPlans`,
  entities: `${baseURL}/entities`,
  targetAudiente: `${baseURL}/entities/targetAudience`,
  broker: `${baseURL}/brokers`,
  account: `${baseURL}/account`,
  company: `${baseURL}/companies`,
  targetAudiences: `${baseURL}/targetAudiences`,
  cookies: `${baseURL}/cookies`,
  citiesIBGE: `${baseURL}/citiesIBGE`,
  assitanceSegmentation: `${baseURL}/assistanceSegments`,
  enterprise: `${baseURL}/enterprises`,
  cepsIBGE: `${baseURL}/cepsIBGE`,
  shoppingCart: `${baseURL}/shoppingCart`,
  shoppingCartItemDetail: `${baseURL}/shoppingCartItemDetail`,

  client: `${baseURL}/client`,
  clientBank: `${baseURL}/clientBank`,

  proposal: `${baseURL}/proposal`,
  proposalApportionment: `${baseURL}/proposalApportionment`,
  proposalDocuments: `${baseURL}/proposalDocuments`,
  proposalProponentDocuments: `${baseURL}/proposalProponentDocuments`,
  proposalProponentDPS: `${baseURL}/proposalProponentDPS`,
  proposalProponentLegalResponsible: `${baseURL}/proposalProponentLegalResponsible`,
  proposalProponentProduct: `${baseURL}/proposalProponentProduct`,
  proposalProponents: `${baseURL}/proposalProponents`,
  proposalFamilies: `${baseURL}/proposalFamilies`,
  proposals: `${baseURL}/proposals`,
  proposalSignature: `${baseURL}/proposalSignature`,
  familyImport: `${baseURL}/movimentacao-cadastral`,

  protheus: `${baseURL}/protheus`,
};
