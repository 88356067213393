<form [formGroup]="formGroup" class="mx-2">
  <mat-radio-group class="my-4" formControlName="accessType">
    <mat-radio-button [value]="1" class="me-5 my-4">
      Corretor
    </mat-radio-button>
    <mat-radio-button [value]="2"> Cliente </mat-radio-button>

    <mat-error
      class="custom-error-msg mt-n4 mb-3"
      *ngIf="shouldShowAccessTypeMsgError"
    >
      Tipo de acesso é obrigatório
    </mat-error>
  </mat-radio-group>

  <!-- Username (Campo exclusivo para corretor) -->
  <mat-form-field appearance="outline" class="w-100" *ngIf="accessType === 1">
    <mat-label>Login</mat-label>

    <input matInput type="text" formControlName="username" />

    <mat-icon *ngIf="formGroup.controls.username.value" matSuffix>
      person
    </mat-icon>

    <mat-error *ngIf="formGroup.controls.username.hasError('invalidLogin')">
      {{ formGroup.controls.username.getError("invalidLogin") }}
    </mat-error>

    <mat-error *ngIf="formGroup.controls.username.hasError('required')">
      Login é obrigatório
    </mat-error>
  </mat-form-field>

  <!-- Campos exclusivos para cliente -->
  <ng-container *ngIf="accessType === 2">
    <!-- CPF -->
    <mat-form-field appearance="outline" class="w-100">
      <mat-label>CPF</mat-label>
      <input
        matInput
        type="text"
        inputmode="numeric"
        formControlName="cpf"
        mask="000.000.000-00"
      />
      <mat-icon
        [class.text-success]="formGroup.controls.cpf.valid"
        *ngIf="formGroup.controls.cpf.value"
        matSuffix
      >
        {{ formGroup.controls.cpf.hasError("invalidCpf") ? "close" : "done" }}
      </mat-icon>

      <mat-error *ngIf="formGroup.controls.cpf.hasError('required')">
        CPF é obrigatório
      </mat-error>

      <mat-error *ngIf="formGroup.controls.cpf.hasError('invalidCpf')">
        Digite um CPF válido
      </mat-error>
    </mat-form-field>

    <!-- E-mail -->
    <mat-form-field appearance="outline" class="w-100">
      <mat-label>E-mail</mat-label>
      <input
        matInput
        type="email"
        formControlName="email"
        [readonly]="readonly"
      />
      <mat-icon
        [class.text-success]="formGroup.controls.email.valid"
        *ngIf="formGroup.controls.email.value"
        matSuffix
      >
        email
      </mat-icon>

      <mat-error *ngIf="formGroup.controls.email.hasError('required')">
        E-mail é obrigatório
      </mat-error>

      <mat-error *ngIf="formGroup.controls.email.hasError('email')">
        Digite um e-mail válido
      </mat-error>
    </mat-form-field>
  </ng-container>

  <ng-container *ngIf="accessType">
    <!-- Senha -->
    <mat-form-field appearance="outline" class="w-100">
      <mat-label>Senha</mat-label>
      <input
        matInput
        [type]="showPass ? 'text' : 'password'"
        name="senha"
        formControlName="password"
        [readonly]="readonly"
      />
      <mat-icon
        [class.text-success]="formGroup.controls.password.valid"
        matSuffix
        *ngIf="!showPass && formGroup.controls.password.value"
        (click)="toggleShowPass()"
      >
        lock
      </mat-icon>

      <mat-icon
        matSuffix
        *ngIf="showPass && formGroup.controls.password.value"
        (click)="toggleShowPass()"
      >
        lock_open
      </mat-icon>

      <mat-error *ngIf="formGroup.controls.password.hasError('required')">
        Senha é obrigatória
      </mat-error>

      <mat-error *ngIf="formGroup.controls.password.hasError('minlength')">
        A senha deve ter ao menos 8 caracteres
      </mat-error>
    </mat-form-field>

    <div class="d-flex justify-content-between mb-3">
      <a
        routerLink="/autenticacao/esqueci-minha-senha"
        class="a-tag-link a-tag-link-text"
        (click)="finished.emit()"
      >
        Esqueci minha senha
      </a>
      <small
        appCapsLock
        (capsLockOn)="setCapsLockOn($event)"
        [class.d-none]="!isCapsLockOn"
      >
        CAPS LOCK ATIVADO
      </small>
    </div>
    <div class="d-flex justify-content-between">
      <app-button
        mat-raised-button
        color="primary"
        class="me-n3"
        type="submit"
        (click)="onSubmit()"
        label="Entrar"
      >
      </app-button>
      <app-button
        mat-raised-button
        color="secondary"
        (click)="finished.emit()"
        routerLink="/autenticacao/cadastrar"
        class="ms-n3"
        type="button"
        label="Cadastrar"
      >
      </app-button>
    </div>
  </ng-container>
</form>
