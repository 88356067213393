import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DefaultResponse } from 'src/app/core/models/DefaultResponse';
import { SignInResponse } from 'src/app/features/auth/interfaces/SignInResponse';
import endpoints from 'src/environments/endpoints';
import { SignOnPayload } from 'src/app/features/auth/interfaces/SignOnPayload';
import { SHOULD_ADD_TENANT_WITHOUT_BRANCH } from '../../interceptors/auth';
import { onlyNumbers } from '../../utils/only-numbers';
import { BrokerSignInPayload } from 'src/app/features/auth/interfaces/BrokerSignInPayload';
import { CreateBrokerPayload } from 'src/app/features/auth/interfaces/CreateBrokerPayload';
import { ClientSignInPayload } from 'src/app/features/auth/interfaces/ClientSignInPayload';
import { ResetClientPasswordPayload } from 'src/app/features/auth/interfaces/ResetClientPasswordPayload';
import { BrokerProtheus } from 'src/app/features/auth/interfaces/BokerProtheus';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private baseURL = endpoints.account;

  private context = new HttpContext().set(
    SHOULD_ADD_TENANT_WITHOUT_BRANCH,
    true
  );

  constructor(private http: HttpClient) {}

  clientSignIn(payload: ClientSignInPayload): Observable<SignInResponse> {
    const url = `${this.baseURL}/signIn`;

    const body = {
      email: payload.email,
      password: payload.password,
      cpf: onlyNumbers(payload.cpf),
    };

    return this.http
      .post<DefaultResponse<SignInResponse>>(url, body, {
        context: this.context,
      })
      .pipe(map((res) => res?.data));
  }

  clientSignOn(payload: SignOnPayload): Observable<any> {
    const url = `${this.baseURL}/create-user`;

    return this.http
      .post<DefaultResponse<any>>(url, payload, {
        context: this.context,
      })
      .pipe(map((res) => res?.data));
  }

  requestClientPassword(
    email: string
  ): Observable<DefaultResponse<string> | string[]> {
    const url = `${this.baseURL}/forgot-password`;

    return this.http.post<DefaultResponse<string> | string[]>(
      url,
      { email },
      { context: this.context }
    );
  }

  resetClientPassword(params: ResetClientPasswordPayload): Observable<void> {
    const url = `${this.baseURL}/reset-password`;

    return this.http.put<void>(url, params);
  }

  getBrokerByCpf(cpf: string): Observable<BrokerProtheus> {
    const url = `${this.baseURL}/broker-user`;

    return this.http
      .post<DefaultResponse<BrokerProtheus>>(
        url,
        { cpf },
        { context: this.context }
      )
      .pipe(map((res) => res?.data));
  }

  brokerSignIn(payload: BrokerSignInPayload): Observable<SignInResponse> {
    const url = `${this.baseURL}/brokerSignIn`;

    return this.http
      .post<DefaultResponse<SignInResponse>>(url, payload, {
        context: this.context,
      })
      .pipe(map((res) => res?.data));
  }

  brokerSignOn(payload: CreateBrokerPayload): Observable<SignInResponse> {
    const url = `${this.baseURL}/broker-create-user`;

    return this.http.post<SignInResponse>(url, payload, {
      context: this.context,
    });
  }

  requestBrokerCredentials(cpf: string): Observable<string> {
    const url = `${this.baseURL}/broker-forgot-password`;

    return this.http
      .post<DefaultResponse<string>>(url, { cpf }, { context: this.context })
      .pipe(map((res) => res?.data));
  }
}
